<template>
  <div>
    <div class="chart3" id="chart3"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  data () {
    return {

    }
  },
  mounted () {
    this.initEcharts3()
  },
  methods: {
    initEcharts3 () {
      let chartDom = document.getElementById('chart3');
      console.log(chartDom, 'chartDomchartDomchartDom');

      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'item'
        },
        grid: { // 让图表占满容器
          top: "10px",
          left: "30px",
          right: "1px"
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisTick: {
            show: false,  // 不显示刻度线
          },
          axisLine: {
            show: false,  // 不显示左侧坐标轴线
            lineStyle: {
              color: '#29D1FD'  // 设置 x 轴线条的颜色
            }
          },

          axisLabel: { // 设置 X 轴标签的字体颜色
            textStyle: {
              color: '#66D0FF' // 你可以替换为你想要的颜色值
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,  // 不显示左侧坐标轴线
          },
          axisTick: {
            show: false,  // 不显示刻度线
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#FFFFFF', '#2B2D6A', '#2B2D6A', '#2B2D6A', '#2B2D6A', '#2B2D6A']
            }
          },
          axisLabel: { // 设置 Y 轴标签的字体颜色
            textStyle: {
              color: '#66D0FF' // 你可以替换为你想要的颜色值
            }
          }
        },
        series: [
          {
            data: [84, 52, 56, 70, 69, 59, 56, 65, 78, 85, 86, 82],
            type: 'bar',
            barWidth: '22px',
            name: 'Data',
            label: {
              show: false,
              position: 'center'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#188DF0' },
                { offset: 0.5, color: '#0090FF' }
              ])
            },
          }
        ]
      };

      option && myChart.setOption(option);

    },
  },
}
</script>
<style scoped>
.chart {
  flex: 1;
  height: 100%;
}

.chart3 {
  flex: 1;
  height: 100%;
  /* color: #a0c4e4; */
}
</style>
