<template>
  <div class="zt">
    <div id="mainsytj" class="tb"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {};
  },
  mounted() {
    var chartDom = document.getElementById("mainsytj");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      title: {
        text: "当日实验室使用统计",
        left: "left",
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        top: "center",
        right: "right",
        textStyle: {
          color: "#fff", // 将图例文字颜色设置为白色
        },
      },
      series: [
        {
          name: "今日使用次数",
          type: "pie",
          radius: "50%",
          center: ["35%", "50%"],
          data: [
            { value: "23", name: "化学实验室" },
            { value: "23", name: "物理实验室" },
            { value: "50", name: "生物实验室" },
            { value: "48", name: "地理实验室" },
            { value: "30", name: "计算机实验室" },
            { value: "32", name: "医学实验室" },
            { value: "12", name: "工程实验室" },
            { value: "6", name: "环境实验室" },
          ],
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>

<style lang="scss" scoped>
.zt {
  width: 100%;
  height: 100%;
  // border: 1px solid pink;
  .tb {
    width: 100%;
    height: 100%;
    background-color: #06223d;
  }
}
</style>