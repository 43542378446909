<template>
  <div style="width: 100%; height: 100%">
    <h2 style="color: #fff">最新预约</h2>
    <dv-scroll-board :config="config" style="width: 100%; height: 80%" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["实验室名称", "预约人", "地点", "预约时间", "管理员"],
        data: [
          ["生物实验室", "淦傲菡", "4楼203", "10/17/12:00", "贺逸云"],
          ["化学实验室", "力同和", "3楼306", "10/19/10:30", "贺逸云"],
          ["生物实验室", "郸琨瑶", "4楼409", "10/20/13:00", "贺逸云"],
          ["物理实验室", "淦傲菡", "2楼203", "10/21/15:30", "贺逸云"],
          ["生物实验室", "肖力言", "4楼409", "10/22/11:00", "贺逸云"],
          ["化学实验室", "力同和", "3楼306", "10/23/14:30", "贺逸云"],
          ["物理实验室", "郸琨瑶", "2楼203", "10/24/09:30", "贺逸云"],
          ["生物实验室", "淦傲菡", "4楼409", "10/25/12:30", "贺逸云"],
          ["化学实验室", "肖力言", "3楼306", "10/26/15:00", "贺逸云"],
          ["物理实验室", "力同和", "2楼203", "10/27/10:30", "贺逸云"],
          ["生物实验室", "郸琨瑶", "4楼409", "10/28/13:30", "贺逸云"],
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>