<template>
  <div class="zt">
    <div id="mainsys" class="zt"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {};
  },
  mounted() {
    var app = {};

    var chartDom = document.getElementById("mainsys");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      color: ["#ee6666", "#5470c6"],
      legend: {
        textStyle: {
          color: "#fff",
        },
      },
      title: {
        text: "学期实验室使用率",
        left: "left",
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {},
      dataset: {
        source: [
          ["product", "工作日", "非工作日"],
          ["一月", "43", "85"],
          ["二月", "83", "73"],
          ["三月", "86", "82"],
          ["四月", "53", "39"],
          ["五月", "43", "29"],
          ["六月", "21", "19"],
        ],
      },
      xAxis: {
        type: "category",
        axisLabel: {
          textStyle: {
            color: "#fff",
          },
        },
      },
      yAxis: {
        axisLabel: {
          textStyle: {
            color: "#fff",
          },
        },
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [{ type: "bar" }, { type: "bar" }],
    };

    option && myChart.setOption(option);
  },
};
</script>

<style lang="scss" scoped>
.zt {
  width: 100%;
  height: 100%;
  // border: 1px solid pink;
  background-color: #06223d;
}
</style>