<template>
  <div style="width: 100%; height: 100%">
    <h1 style="color: #fff">{{ title }}</h1>
    <div class="skxx">
      <div class="xsxx" v-for="item in skxx" :key="item.id">
        <div class="l">
          <p>
            <span>{{ item.name }}</span> {{ item.time }}
          </p>
          <p>{{ item.sys }}</p>
        </div>
        <div
          class="r"
          :style="{ backgroundColor: item.sf ? '#279946' : '#dd001b' }"
        >
          <p>
            {{ item.sf ? "进入" : "离开" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "skjl",
  data() {
    return {
      title: "当日刷卡进出记录",
      skxx: [
        { name: "淦傲菡", time: "14:18", sys: "计算机实验室", id: 1, sf: 0 },
        { name: "力同和", time: "08:18", sys: "电子工艺实验室", id: 2, sf: 1 },
        { name: "郸琨瑶", time: "14:18", sys: "机械实验室", id: 3, sf: 1 },
        { name: "肖力言", time: "12:18", sys: "材料实验室", id: 4, sf: 0 },
        { name: "温楚洁", time: "10:18", sys: "化学实验室", id: 5, sf: 1 },
        { name: "韶曼柔", time: "14:18", sys: "物理实验室", id: 6, sf: 0 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.skxx {
  width: 100%;
  height: 80%;
  // background-color: pink;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  .xsxx {
    width: 30%;
    height: 40%;
    border: 1px solid #58a6bb;
    border-radius: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .l {
      color: #fff;
      span {
        font-size: 20px;
      }
    }
    .r {
      width: 20%;
      height: 30%;
      color: #fff;
      background-color: #3de475;
      border-radius: 10px;
      padding: 3px;
      // 文字水平垂直居中
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>