<template>
  <div class="aaa">
    <div id="main"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "yxtj",
  data() {
    return {};
  },
  mounted() {
    var chartDom = document.getElementById("main");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      title: {
        text: "当日运行时机统计",
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        bottom: "5%",
        data: ["电子实验室", "生物实验室", "化学实验室", "物理实验室"],
        textStyle: {
          color: "#fff",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "10%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
        ],
        axisLabel: {
          textStyle: {
            color: "#fff",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          textStyle: {
            color: "#fff",
          },
        },
      },
      series: [
        {
          name: "电子实验室",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230, 210, 412, 321, 441],
          smooth: true,
        },
        {
          name: "生物实验室",
          type: "line",
          stack: "Total",
          data: [220, 182, 191, 234, 290, 330, 310, 134, 90, 230],
          smooth: true,
        },
        {
          name: "化学实验室",
          type: "line",
          stack: "Total",
          data: [150, 232, 201, 154, 190, 330, 410, 234, 290, 330],
          smooth: true,
        },
        {
          name: "物理实验室",
          type: "line",
          stack: "Total",
          data: [320, 332, 301, 334, 390, 330, 320, 154, 190, 330],
          smooth: true,
        },
      ],
    };
    option && myChart.setOption(option);
  },
};
</script>

<style lang="scss" scoped>
.aaa {
  width: 100%;
  height: 100%;
  background-color: #06223d;
  #main {
    width: 100%;
    height: 100%;
  }
}
</style>