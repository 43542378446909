<template>
  <div>
    <div class="centerBoxC" id="centerBoxC"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.initEcharts1()
  },
  computed: {

  },
  methods: {
    initEcharts1 () {
      var chartDom = document.getElementById('centerBoxC');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 70,
        //   bottom: 50,
        //   textStyle: { // 设置字体颜色
        //     color: '#fff' // 你可以替换为你想要的颜色值
        //   }
        // },
        series: [
          {
            name: 'Data',
            type: 'pie',
            // center: ['40%', '50%'], // 水平居中
            label: {
              show: false,
              position: 'center'
            },
            data: [
              { value: 35, name: '赛事完成率', itemStyle: { color: '#1374FF' } },
              { value: 44, name: '赛事未完成率', itemStyle: { color: '#29D1FD' } },
            ]
          }
        ]
      };

      option && myChart.setOption(option);

    },
  },
}
</script>
<style scoped>
.centerBox .centerBoxC {
  flex: 1;
  height: 100%;
}
</style>
