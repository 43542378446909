<template>
  <div>
    <dv-full-screen-container>
      <!-- <dv-loading>Loading...</dv-loading> -->
      <div class="zt">
        <div class="row rl">
          <div class="cell clt">
            <dv-border-box-12>
              <div class="xzxys">
                <span>请选择实验室：</span>
                <select>
                  <option v-for="lab in spdz" :value="lab.id" :key="lab.id">
                    {{ lab.name }}
                  </option>
                </select>
              </div>
              <div class="xzxysjksp">
                <video
                  :src="spdz[0].video"
                  muted
                  loop
                  autoplay
                  width="150%"
                  @click="djsp"
                ></video>
              </div>
            </dv-border-box-12>
          </div>
          <div class="cell clb">
            <dv-border-box-12>
              <div class="xutj">
                <yxtj></yxtj>
              </div>
            </dv-border-box-12>
          </div>
        </div>
        <div class="row rr">
          <div class="cell crt">
            <dv-border-box-12>
              <div class="xutj">
                <skjl></skjl>
              </div>
            </dv-border-box-12>
          </div>
          <div class="cell crc">
            <dv-border-box-12>
              <div class="xutj">
                <zxyy></zxyy>
              </div>
            </dv-border-box-12>
          </div>
          <div class="crb">
            <div class="crb1">
              <dv-border-box-12>
                <div class="xutj">
                  <sytj></sytj>
                </div>
              </dv-border-box-12>
            </div>
            <div class="crb1">
              <dv-border-box-12>
                <div class="xutj">
                  <syssyl></syssyl>
                </div>
              </dv-border-box-12>
            </div>
          </div>
        </div>
        <!-- <div class="an">
          <div class="ann">
            <router-link to="ym2">ym2</router-link>
            <router-link to="ym3">ym3</router-link>
          </div>
        </div> -->
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import yxtj from "../components/ym1/yxtj.vue";
import skjl from "../components/ym1/skjl.vue";
import zxyy from "../components/ym1/zxyy.vue";
import sytj from "../components/ym1/sytj.vue";
import syssyl from "../components/ym1/syssyl.vue";
export default {
  name: "Home1",
  data() {
    return {
      spdz: [
        {
          name: "电子综合实验室",
          video: require("../assets/11_50_35-11_52_13-98049.mp4"),
          id: 1,
        },
        {
          name: "电子电工实验室",
          video: require("../assets/11_50_35-11_52_13-98049.mp4"),
          id: 2,
        },
        {
          name: "综合实验室",
          video: require("../assets/11_50_35-11_52_13-98049.mp4"),
          id: 3,
        },
      ],
      bfsp: false,
    };
  },
  methods: {
    djsp() {
      // 获取视频元素
      const videoElement = document.querySelector("video");

      // 如果不是全屏，则进入全屏
      if (!this.bfsp) {
        // 判断是否支持requestFullscreen
        if (videoElement.requestFullscreen) {
          // 进入全屏
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          // 进入全屏
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
          // 进入全屏
          videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
          // 进入全屏
          videoElement.msRequestFullscreen();
        }
        // 设置为true，表示已经进入全屏
        this.bfsp = true;
      } else {
        // 如果已经处于全屏，则退出全屏
        // 判断是否支持exitFullscreen
        if (document.exitFullscreen) {
          // 退出全屏
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // 退出全屏
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // 退出全屏
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // 退出全屏
          document.msExitFullscreen();
        }
        // 设置为false，表示已经退出全屏
        this.bfsp = false;
      }
    },
  },
  components: {
    yxtj,
    skjl,
    zxyy,
    sytj,
    syssyl,
  },
};
</script>``

<style lang="scss" scoped>
.zt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-image: url("../assets/nybj.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.row {
  height: 100%;
  width: 100%;
  .cell {
    width: 100%;
    // background-color: pink;
    padding: 5px;
  }
}
// .an {
//   width: 100%;
//   height: 5%;
//   background-color: #06223d;
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   z-index: 99999;
//   .ann {
//     width: 5%;
//     height: 80%;
//     border: 1px solid red;
//   }
// }
.rl {
  width: 35%;
}
.rr {
  width: 65%;
}

.clt {
  height: 55%;
  padding: 10px;
  .xzxys {
    width: 100%;
    height: 10%;
    padding: 10px;
    line-height: 35px;
    color: #7ce7fd;
    display: flex;
    justify-content: center;
    select {
      width: 70%;
      background-color: #06223d;
      color: #7ce7fd;
    }
  }
  .xzxysjksp {
    width: 95%;
    height: 90%;
    margin: 0 auto;
    overflow: hidden;
  }
}
.clb {
  height: 45%;
}

.crt {
  height: 30%;
}
.crc {
  height: 25%;
}
.crb {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: space-between;
}
.crb1 {
  width: 50%;
  height: 100%;
  padding: 5px;
}

.xutj {
  width: 90%;
  height: 90%;
  // 相对定位垂直居中
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // border: 1px solid #7ce7fd;
}
</style>