<template>
  <div id="app">
    <div class="cs">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import ym1 from "@/views/ym1.vue";
export default {
  name: "App",
  data () {
    return {
      sx: 1,
    };
  },
  mounted () {

  },
  components: {
    ym1,
  },
  methods: {},
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0px;
  list-style: none;
  box-sizing: border-box;
}

#app {
  width: 100vw;
  height: 100vh;
}

.cs {
  width: 100%;
  height: 100vh;
  border: 1px solid red;
}
</style>
